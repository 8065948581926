import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import BecomeACarrier from '../components/BecomeACarrier'

const BusinessServicesPage = ({ data }) => {
  const postNode = {
    title: `Business Services - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Business Services - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="business-services" customTitle />

      <ServiceContainer>
        <PageTitle>Business Services</PageTitle>
        <LargeP>
          With over three decades of experience, D&D understands the delicate
          balance of running a business and getting the job done efficiently.
        </LargeP>
        <p>
          It takes great effort to run an efficient transportation business. We
          will help manage the compliance, paperwork and logistics while you
          drive your bottom line. Our team will help your operations run
          seamless, efficient and error free so you can focus on making a
          stronger bottom line for you and your family.
        </p>

        <BecomeACarrier />

        <h4>Business Services at a Glance</h4>
        <FlexList>
          <FlexItem>Personnel on call 24/7</FlexItem>
          <FlexItem>Online Load Posting</FlexItem>
          <FlexItem>Online Load Tracking</FlexItem>
          <FlexItem>Online Document Retrieval</FlexItem>
          <FlexItem>Electronic Wire Funds Transfer</FlexItem>
          <FlexItem>Specialized Reporting</FlexItem>
          <FlexItem>Satellite Tracking</FlexItem>
          <FlexItem>Qualcomm Communication</FlexItem>
          <FlexItem>Full Service Maintenance Facility</FlexItem>
          <FlexItem>Regulatory Compliance</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default BusinessServicesPage
